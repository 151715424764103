import React, { Component } from 'react';

export class ShowSystem extends Component {
    static displayName = ShowSystem.name;

  constructor(props) {
    super(props);
      this.state = { systemdata: [], loading: true, lab1: "", lab2: "" };
  }

    componentDidMount() {
        this.populateData();
  }

  // componentDidUpdate() {
  //     this.populateData();
  // }

  async mcDetEvent(mc1, id, det) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "id1": id,
                "detect": det
            })
    };
    await fetch(mc1 + 'DetEvent', requestOptions);
    var response = await fetch(mc1+'DetEvent', requestOptions);
    // var res = await response.text();
  }

    async mcInfoEvent(mc1, id1, light, flash, sound) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    "id1": id1,
                    "light": light,
                    "flash": flash,
                    "sound": sound
                })
        };
        await fetch(mc1 + 'InfoEvent', requestOptions);
        // var response = await fetch(mc1 + 'InfoEvent', requestOptions);
        // var res = await response.text();
  }


  handleClick = (id, pos, detect, mc1, that) => {
     async function call() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "id1": id, "detect": !detect })
        };
         var response = await fetch('emu/LcDet', requestOptions);
         var res = await response.text();
         if (res !== 'ok') {
             that.setState({ lab2: 'LC error: ' + res });
         }
         else {
             that.setState({ lab2: mc1 + "DetEvent {" + id + ", " + !detect + "}" });
             that.mcDetEvent(mc1, id, !detect);
         }
     }
      that.setState({ lab1: "internal LC: " + pos + " ==> " + !detect });
      // testing
      // mc1 = "http://localhost:37866/dev1/";
      mc1 = "https://psm1.mobparking.eu/dev1/";
      call();
  }


  myclass = sysstate => {
      if (sysstate) return "btn btn-warning";
      else return "btn btn-light";
  }

  static renderTable(that)
  {
     return(
         <table id="datatable" className='table table-striped' aria-labelledby="tabelLabel">
            <thead>
                <tr>
                    <th>Pos</th>
                    <th>Detect</th>
                    <th>Light</th>
                    <th>Flash</th>
                    <th>Sound</th>
                    <th>Duration</th>
                    <th>NLight</th>
                    <th>NFlash</th>
                    <th>NSound</th>
                </tr>
            </thead>
            <tbody>
                {this.myclass}
                 {that.state.systemdata.map(sd =>
                    <tr key={sd.id1}>
                        <td>{sd.pos}</td>
                         <td><button onClick={() => that.handleClick(sd.id1,
                                          sd.pos, sd.detect, sd.mc1, that)}
                                     className={that.myclass(sd.detect)}>
                               {'' + sd.detect}
                             </button>
                        </td>
                         <td style={{
                             background:
                                 ((sd.light === '000000') ? "gray" : "#"+sd.light)
                         }}>
                             { sd.light }</td>
                        <td>{'' + sd.flash}</td>
                        <td>{'' + sd.sound}</td>
                         <td>{(sd.duration === 0) ? 0 : Math.max(0,
                             (sd.duration - Math.trunc((Date.now() - sd.iniTick) / 1000)))}</td>
                        <td>{sd.nlight}</td>
                        <td>{'' + sd.nflash}</td>
                        <td>{'' + sd.nsound}</td>
                    </tr>
                )}
            </tbody>
         </table>
     );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
          : ShowSystem.renderTable(this);

    return (
        <div>
          <meta http-equiv="refresh" content="5"/>
          <h1 id="tableLabel" >System</h1>
          <p>List of Controllers and Devices</p>
            {contents}
            <label id="repLabel1">{ this.state.lab1 }</label><br />
            <label id="repLabel1">{ this.state.lab2 }</label>
        </div>
      );
      //          <meta http-equiv="refresh" content="5"/>
  }

  async saveState( req ) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "id1": req.id1,
                "light": req.light,
                "flash": req.flash,
                "sound": req.sound,
                "duration": req.duration,
                "nlight": req.nlight,
                "nflash": req.nflash,
                "nsound": req.nsound
            }        )
    };
    var response = await fetch('emu/SetState', requestOptions);
    var res = await response.text();
    if (res !== 'ok') {
        this.setState({ lab1: 'LC error: ' + res });
    }
    else {
        this.setState({ lab1: 'LC state saved' })
    }
  }

  async populateData() {
    const response = await fetch('emu/getLCs');
      const data = await response.json();
      data.map(i => {
          if ((i.duration > 0) && (Math.trunc((Date.now() - i.iniTick) / 1000) > i.duration))
          {
              var n = {
                  "id1": i.id1,
                  "light": i.nlight,
                  "flash": i.nflash,
                  "sound": i.nsound,
                  "duration": 0,
                  "nlight": '',
                  "nflash": false,
                  "nsound": false
              }
              this.saveState(n);
              this.setState({
                  lab2: i.mc1 + 'InfoEvent {' + n.id1 + ', ' + n.light + ', '
                      + n.flash + ', ' + n.sound + '}'
              });
              // this.mcInfoEvent(i.mc1, n.id1, n.light, n.flash, n.sound);
          }
      });
      this.setState({ systemdata: data, loading: false } );
  }
}
